import React from 'react';
import { Grid } from '@material-ui/core';
import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";

import Footer from "components/Footer/Footer.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);

function HtmlIntro(props) {
    const classes = useStyles();
    const rest = { ...props };
    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>What is a web page ?</h1>
                    <p className={classes.main}>A web page is essentially a text file containing instructions that define what should be displayed and how it looks in a web browser.  This can be headings, paragraphs of text, labels, buttons, tables, etc.
                This can be on a Personal Computer, laptop, tablet, phone, even watches and other smart devices.
                An example web page could look like the following...
    </p>
                    <pre><code>
                        &lt;!DOCTYPE html&gt;
                &lt;html&gt;<br />
                        &lt;head&gt;<br />
                        &lt;Title&gt;Example Web Page &lt;Title/&gt;<br />
                        &lt;/head&gt;<br />
                        &lt;body&gt;<br />
                        &lt;h1&gt;Welcome&lt;h1&gt;<br />
                        &lt;p&gt;This is an example web page &lt;/p&gt;<br />
                        &lt;/body&gt;<br />
                        &lt;/html&gt;
            </code>
            </pre>
                    
                    <h1 className={classes.title}> What are these instructions ?</h1>
                    <p className={classes.main}>A web page is created using HyperText Mark - up Language(HTML) which was invented by Tim Berners - Lee in 1992 whilst working for CERN, the European Laboratory for Particle Physics in Geneva, Switzerland.</p>
                    <p className={classes.main}>Tim wanted to create a standard format for research documents which could be easily linked together to allow the information to be more easily understood. <a href="https://www.w3.org/People/Raggett/book4/ch02.html">w3org.com</a></p>
                    <p className={classes.main}>Tim created a new application which could understand the instructions and display the page as designed on the NeXT computer.Today, we have many web browsers available including:
            </p>

                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <h2 className={classes.title}> Microsoft Edge</h2>
                            <img alt="Microsoft Edge" src={require("assets/img/internet-explorer.png")} />
                        </Grid>
                        <Grid item xs={3}>
                            <h2 className={classes.title}>Google Chrome</h2>
                            <img alt="Google Chrome" src={require("assets/img/google-chrome.jfif")} />
                        </Grid>
                        <Grid item xs={3}>
                            <h2 className={classes.title}>Firefox</h2>
                            <img alt="Firefox" src={require("assets/img/firefox.jfif")} />
                        </Grid>
                        <Grid item xs={3}>
                            <h2 className={classes.title}>Safari</h2>
                            <img alt="Safari" src={require("assets/img/safari.jfif")} />
                        </Grid>
                    </Grid>

                    <h1 className={classes.title}>What does this language mean?</h1>
                    <p className={classes.main}>A HTML page is made up of "elements" which are enclosed in less than (&lt;) and greater than (&gt;) symbols.</p>
                    <p className={classes.main}>In the example shown earlier, there were many HTML elements including: </p>
                    <h1 className={classes.title}>HTML DOCTYPE</h1>
                    <p className={classes.main}>The &lt;!DOCTYPE html&gt; statement found at the top of the HTML page indicates that the Web Page is using HTML Version 5. It must only appear once at the top of the page before any other HTML element</p>
                    <h1 className={classes.title}>&lt;html&gt;&lt;/html&gt;</h1>
                    <p className={classes.main}>This element is used to define the start and end of the HTML document. &lt;html&gt;&lt; does not sit within other elements. </p>

                    <h1 className={classes.title}>&lt;head&gt;</h1>
                    <p className={classes.main}>The head HTML element is used to store information about the page that is usually not visible such as links to other files that are used by the page. &lt;head&gt; needs to be within the &lt;html&gt; HTML element.</p>

                    <h1 className={classes.title}>&lt;body&gt;&lt;/body&gt;</h1>
                    <p className={classes.main}>The body element defines where the visible portion of the page is defined. &lt;body&gt; needs to be within the &lt;html&gt; HTML element.</p>

                    <h1 className={classes.title}>Useful information to be aware of</h1>
                    <p className={classes.main}>HTML is not bothered about the case of the HTML element. So it does not matter if you type &lt;html&gt; or &lt;HTML&gt;, the web browser will
                understand and react accordingly to both.</p>
                    <p className={classes.main}>HTML elements need to define where they start and end. This is done using a starting tag and then an closing tag with content in the middle. For example, if you wanted a Paragraph element, you would use &lt;p&gt; to start followed by a closing tag of &lt;/p&gt;. The information or content you wished to display would be included in between the two tags. </p>
                    <img alt="HTML Paragraph tag" src={require("assets/img/paragraph-tag.JPG")} />
                    (<a alt="Tutorial Republic website" href="https://www.tutorialrepublic.com/html-tutorial/html-elements.php">Tutorial Republic</a>)
            <p>If there is no content included in the HTML element, then it is possible to close the element using a short cut. For example, the Horizontal
                rule element does not have any content as its sole purpose is to include a horizontal line. The HR element is purely written as <code>&lt;hr/&gt;</code>.
                                This short hand has the closing tag built into it using the forward slash ("/").
            </p>
                    <p className={classes.main}>
                        This short hand to close a tag can be used with most other elements where content is not required. E.g. a picture HTML element called &lt;img&gt; doesn't
                require a closing tag as it is written as <code>&lt;img alt="picture showing user" src="user.jpg"/&gt;</code>.
            </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HtmlIntro;
