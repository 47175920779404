import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Image from 'material-ui-image';
import Footer from "components/Footer/Footer.js";

import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

// nodejs library that concatenates classes
import classNames from "classnames";

import { Link } from '@material-ui/core';

const useStyles = makeStyles(styles);

function CodePenTour(props) {
    const classes = useStyles();
    const rest = { ...props };

    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>
                        CodePen - tour
                    </h1>
                    <br/>
            <h2  className={classes.title}>Login</h2>
            <p className={classes.main}>To login to CodePen, you will need to do the following from the main page:</p>
            <ol>
                <li>Open CodePen by click on <Link href="https://www.codepen.io" target="_new-window">https://www.codepen.io</Link></li>
                <li>Click on the "Log In" button located at the top right hand side of the page.</li>
                <p className={classes.main}>
                <Image src={require("assets/img/CodePenLogin.png")} aspectRatio={(16/9)}/>
                </p>
                <li>At the login details page, either enter your email address and password you used during registration or select one of the company logins 
                    you used.
                    
                </li>
                <p className={classes.main}>
                <Image src={require("assets/img/CodePenLoginDialog.PNG")} aspectRatio={(16/9)}/>
                </p>
                <li>Once you have logged in, you will be taken to the following page:</li>
                <Image src={require("assets/img/CodePenEmailVerified.png")} aspectRatio={(16/9)}/>
            </ol>
            <h2 className={classes.title}>You have successfully logged into CodePen, Well done!!</h2>
            <br/>
            <h2 className={classes.title}>
                Pen
            </h2>
            <p className={classes.main}>
                A pen is used to create a single web page which will contain HTML along with any JavaScript and CSS you require.
            </p>
            <p className={classes.main}>
                To create a new "Pen", login to CodePen by clicking <Link href="#login">Go To Login Section</Link>
            </p>
                </div>
                </div>
                <Footer/>
                </div>
    );
}
export default CodePenTour;
