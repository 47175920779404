import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Image from 'material-ui-image';
import Footer from "components/Footer/Footer.js";

import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function HtmlLab1(props) {
    const classes = useStyles();
    const rest = { ...props };

    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>Lab 1 - Lets create your first webpage</h1>
                    <h2 className={classes.title}>Tools required</h2>
                    
                    <p className={classes.main}>CodePen</p>

                    <h2 className={classes.title}>Steps</h2>
                    <ol>
                        <li>Open CodePen and login.</li>
                        <li>Create a new "Pen".</li>
                        <li> Type the following text into your "Pen":</li>
                        <br />
                        <br />
                        <code>
                            &lt;!DOCTYPE html&gt;
                                &lt;html&gt;
                            <br />
                            &lt;head&gt;
                                <br />
                            &lt;Title&gt; My first web page Page &lt;/Title&gt;
                                <br />
                            &lt;/head&gt;
                                <br />
                            &lt;body&gt;
                                <br />
                            &lt;h1&gt; Welcome &lt;/h1&gt;
                                    <br />
                            &lt;p&gt;This is my first web page:)&lt;/p&gt;
                                    <br />
                            &lt;/body&gt;
                                <br />
                            &lt;/html&gt;
                        </code>
                        <br />
                        <br />
                        <li>Save the Pen as "First Web Page".</li>
                        <li>
                            The preview tab should show a white page with the text "This is my first web page :)" should be displayed.At the top of your web browser you should also have a title of "My first web page Page".</li>
                    </ol>
                    <hr />
                    <Image alt="first webpage ever created" src={require("assets/img/my-first-webpage.png")} aspectRatio={(8 / 10)} />
                    <hr />

                    <h2 className={classes.title}>If you have time</h2>
                    <ol>
                        <li>Open CodePen</li>
                        <li>Open "First Web Page" and review the HTML</li>
                        <li>Change the message "This is my first webpage" to something else.</li>
                        <li>Check that the preview tab shows the new message.</li>
                    </ol>

                </div>
            </div>
            <Footer/>
        </div>
    );
};
