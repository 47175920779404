import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// styling
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// load the styles for use.
const useStyles = makeStyles(styles);

const dashboardRoutes = [];

export default function PageHeaderSection(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (

        <div>
            <Header
                color="transparent"
                routes={dashboardRoutes}
                brand="Kinetic Code"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax filter image={require("assets/img/hackers.jpg")}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h1 className={classes.title}>Welcome!</h1>
                            <h4>
                                This web site has been created to help allow anyone new to development to be able to
                                have a go. No knowledge is assumed so please have a read, give the labs a go and
                                most of all, have fun!!
                    </h4>
                            <br />
                            <Button
                                color="danger"
                                size="lg"
                                href="https://www.youtube.com/channel/UC95EaSn5KJdxuL56yZQodcg?view_as=subscriber"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fas fa-play" />
                                Checkout out my YouTube Videos
                    </Button>
                        </GridItem>
                    </GridContainer>
                </div>
            </Parallax>
        </div>
    );
}