import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Image from 'material-ui-image';
import Footer from "components/Footer/Footer.js";

import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function JsLab2(props) {
    const classes = useStyles();
    const rest = { ...props };

    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>Lab 4 - Talk to the user</h1>
                    <br/>
                    <h2 className={classes.title}>Tools required</h2>
                    <ul>
                        <li>Internet access</li>
                        <li>Registered account with CodePen</li>
                    </ul>

                    <h2 className={classes.title}>Steps</h2>
                    <ol>
                        <li>Login to CodePen with your personal account.</li>
                        <li>Create a new Pen using your basic HTML template.</li>
                        <li>Review and enter the the following code in the HTML section:
                        <p className={classes.main}><Image src={require("assets/img/javascript-lab2-html.JPG")} aspectRatio={(16 / 1)} /></p>
                        </li>

                        <li>Review and enter the code into the JavaScript section:</li>
                        <p className={classes.main}><Image src={require("assets/img/javascript-lab2-javascript.JPG")} aspectRatio={(16 / 9)} /></p>
                        <p className={classes.main}>When you have completed your updates, you should have the following setup:</p>
                        <p className={classes.main}><Image src={require("assets/img/javascript-lab2-full-view.JPG")} aspectRatio={(16 / 9)} /></p>
                        <li>Once you have entered all the information, enter your name and click the "Say My Name" button. What happens?</li>
                        <li>Save the Pen as "JavaScript - talk to user".</li>
                    </ol>

                    <h2 className={classes.title}>If you have time</h2>
                    <ol>
                        <li>Open the Pen "JavaScript - talk to user."</li>
                        <li>Change the name of the pen to "JavaScript - talk to user - time permitting"</li>
                        <li>Add a new Label and input between the current input and the button "Say My Name". To space the label and input out from the other HTML elements on the page, include them
                            in a paragraph HTML element. E.g. &lt;p&gt;&lt;/p&gt;
                    </li>
                        <li>Update the new input to have an id="txtUserAge"</li>
                        <li>Update the JavaScript function Welcome to:
                        <ul>
                                <li>Retrieve and store the user's age in a new variable called userAge. This will be done by getting a link to txtUserAge and retrieving the value from the input.</li>
                                <li>Amend the line "userLogLabel.innerText =
    "Welcome [" + userName + '] you have got ' + userName.length + " letters in your name.";" to include " and your age is " + userAge. </li>
                            </ul>
                        </li>
                        <li>Save and click on the button "Say my name?" button.</li>

                    </ol>
                </div>
            </div>
            <Footer/>
        </div>
    );
};
