import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Footer from "components/Footer/Footer";

// Sections for this page
// import WorkSection from "views/LandingPage/Sections/WorkSection.js";
import KidsLearningPlan from "views/KidsPage/Sections/KidsLearningPlan";

// styling
import styles from "assets/jss/material-kit-react/views/KidsPage";

// sections of the page.
import KidsHeaderSection from "views/KidsPage/Sections/KidsHeaderSection";

// load the styles for use.
const useStyles = makeStyles(styles);

export default function KidsPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    return (
        <div>
            <KidsHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <KidsLearningPlan />
                </div>
            </div>
            <Footer />
        </div>   
        );
}
