import React from 'react';
import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";
import { Link } from '@material-ui/core';
import Image from 'material-ui-image';

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";

import Footer from "components/Footer/Footer.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);

function CodePenRegistration(props) {
    const classes = useStyles();
    const rest = { ...props };
    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>CodePen</h1>
                    <p className={classes.main}>
                        During the Code Club workshop, we are going to use an online editor to create web pages, make them interactive and make them look nice.
                        The reason for this choice is that an online code editor such as Code Pen allows you to preview how your web page is displayed
                        to see what it will look like. There are no additional software that needs to be downloaded and installed as most modern operating systems provide a web browser.
                        Code Pen also allows you to save your work so that you can take it home with you once you have completed the workshop and carry on your coding journey.
            </p>
                    <p className={classes.main}>
                        Before we start any hands on coding, you will need to register a new account (if you have not already) to allow you to save your work within Code Pen.
                        To do this, you will require a computer and internet connection.
            </p>
                    <p className={classes.main}>Once you have got an internet connection, you will need to do the following:
                <ol>
                            <li>Open a web browser and go to <Link href="https://www.codepen.io">www.codepen.io</Link></li>
                            <p className={classes.main}>You should see a page like this...</p>
                            <p className={classes.main}>
                                <Image src={require('assets/img/CodePenMainPage.png')} aspectRatio={(16 / 9)} />
                            </p>
                            <li>
                                Click on the "Sign Up" button located at the top right hand side of the page. This will
                            take you to the Free registration page. You can signup using accounts from other companies such as Twitter, GitHub or Facebook. You will also
                    have the choice of signing up with email and password. Please select one method to sign up.</li>
                            <p className={classes.main}>
                                <Image src={require("assets/img/CodePenSignup.png")} aspectRatio={(16 / 9)} />
                            </p>
                            <li>
                                As part of this sign in process, you will be required to verify that CodePen is using the right email address so will send an email to either the
                                email address you provided or the one associated with the other company account you used. If you do not verify this then you will not be able to
                                use CodePen. Please check your email address, locate the email from CodePen and click on "Click to verify button" in the email.
                                If you cannot locate the email, check your junk or spam folder.
                    </li>
                            <li>
                                Once you have verified your email for your account, you will be taken to the main page for CodePen. This page should now show as follows:
                        <p className={classes.main}>
                                    <Image src={require("assets/img/CodePenEmailVerified.png")} aspectRatio={(16 / 9)} />
                                </p>
                            </li>
                        </ol>
                        <p className={classes.main}>You are now ready to start development and your code will be stored on Code Pen and available when you log in anywhere.</p>
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default CodePenRegistration;
