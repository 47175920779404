import React from 'react';
import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";

import Footer from "components/Footer/Footer.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function CssIntro(props) {
    const classes = useStyles();
    const rest = { ...props };
    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>How can I make my web page look good?</h1>
                    <p className={classes.main}>CSS stands for <b>C</b>ascading <b>S</b>tyle <b>S</b>heets</p>
                    <p className={classes.main}>CSS is used to define how HTML elements are displayed on the screen from the colour, position, border, etc.</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}
