import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Image from 'material-ui-image';
import Footer from "components/Footer/Footer.js";

import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);
export default function JsLab1(props) {
    const classes = useStyles();
    const rest = { ...props };
    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>Lab 3 - Create first interact web page</h1>
                    <h2 className={classes.title}>Tools required</h2>
                    <ul>
                        <li>Internet access</li>
                        <li>Registered account with CodePen</li>
                    </ul>
                    <h2 className={classes.title}>Steps</h2>
                    <ol>
                        <li>Login to CodePen with your personal account.</li>
                        <li>Create a new Pen using your basic HTML template.</li>
                        <li>Review and enter the following text into the HTML window within CodePen:
                    <p className={classes.main}>
                                <Image alt="Hello World example" src={require("assets/img/javascript-intro-hello-world-code.PNG")} aspectRatio={7/4}/>
                            </p>
                        </li>
                        <li>Save the Pen as "JavaScript Lab 1"</li>
                        <p className={classes.main}>
                            In the preview section of CodePen, you should now see a white page
                            with the text "First Hello World Application using JavaScript" displayed.
                            You should have a button on the page called "Click me!".
                    </p>
                        <p className={classes.main}>Click on the button, does anything happen?</p>
                        <hr />
                        <Image alt="My first interactive web page" src={require("assets/img/lab3-first-interactive-web-page.png")} aspectRatio={16/9}/>
                        <hr />
                    </ol>

                    <h2 className={classes.title}>If you have time...</h2>
                    <ol>
                        <li>Login to CodePen with your personal account.</li>
                        <li>Re-open the Pen "JavaScript Lab 1".</li>
                        <li>Add another button to the page. In this new button, you need call a new function call <b>HelloWorldAgain()</b> so modify the onclick event to do this.</li>
                        <li>Change the button caption to <b>And then click me!</b>
                        </li>
                        <li>In the &lt;script&gt; section of the page, create a new function by copying <b>HelloWorld()</b> and renaming the function to <b>HelloWorldAgain()</b></li>
                        <li>Have a look at the preview section of CodePen.</li>
                        <li>click on the <b>Click me button!!</b> first - what do you see?</li>
                        <li>click on the <b>And then click me!!</b> next - what do you see?</li>
                    </ol>

                </div>
            </div>
            <Footer/>
        </div>
    );
};
