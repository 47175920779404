import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import HomePage from "views/HomePage/HomePage.js";
import HtmlIntro from "views/Tutorials/html/HtmlIntro.js";
import HtmlFormatting from "views/Tutorials/html/HtmlFormatting.js";
import CssIntro from "views/Tutorials/css/CssIntro.js";
import JavaScriptIntro from "views/Tutorials/javascript/JavaScriptIntro.js";

// code pen
import CodePenRegistration from "views/Tutorials/codepen/CodePenRegistration.js";
import CodePenTour from "views/Tutorials/codepen/CodePenTour.js";

// labs
import JsLab1 from "views/Labs/javascript/JsLab1.js";
import JsLab2 from "views/Labs/javascript/JsLab2.js";
import HtmlLab1 from "views/Labs/html/HtmlLab1.js";
import HtmlLab2 from "views/Labs/html/HtmlLab2.js";

import KidsPage from "views/KidsPage/KidsPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/login-page" component={LoginPage} />
      <Route path="/home-page" component={HomePage} />
      <Route path="/components" component={Components} />
      <Route path="/html-intro" component={HtmlIntro} />
      <Route path="/html-formatting" component={HtmlFormatting} />
      <Route path="/css-intro" component={CssIntro} />
      <Route path="/javascript-intro" component={JavaScriptIntro} />
      <Route path="/codepen-registration" component={CodePenRegistration} />
      <Route path="/JsLab1" component={JsLab1} />
      <Route path="/JsLab2" component={JsLab2} />
      <Route path="/HtmlLab1" component={HtmlLab1} />
      <Route path="/HtmlLab2" component={HtmlLab2} />
      <Route path="/codepen-tour" component={CodePenTour} />
      <Route path="/kids" component={KidsPage} />
      <Route path="/" component={HomePage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
