import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";

import Footer from "components/Footer/Footer.js";
import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);

function JavaScriptIntro(props) {
    const classes = useStyles();
    const rest = { ...props };

    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>What is JavaScript ?</h1>
                    <p className={classes.main}>JavaScript is a language which you can use to create an interactive web site.</p>
                    <p className={classes.main}>An example of JavaScript can be seen below:</p>
                    <img alt="Hello World JavaScript example" src={require("assets/img/javascript-intro-hello-world-code.PNG")} />
                    <p className={classes.main}>The above page has the standard &lt;html&gt; page format including a &lt;head&gt; and &lt;body&gt;</p>
                    <p className={classes.main}>JavaScript code can be included in a web page by adding the &lt;script&gt; element in the &lt;head&gt; section of the document. The <b>Type</b> element
            of "JavaScript" indicates to the browser that its JavaScript code. </p>
                    <p className={classes.main}>In JavaScript, you can create blocks of text which can be labelled with a name called a <b>function</b>. Functions can be initiated from most places on the page including
            with the script section of the page or from events generated from within the page. An example of an event is when a user clicks on a button or known in short as <i>onclick</i>.</p>
                    <p className={classes.main}>In the example page, there is a new function called HelloWorld. The HelloWorld function's only job is to call something called Alert() which when provided with some information
                will display this to the user.</p>
                    <p className={classes.main}>In the &lt;body&gt; there is a Heading 1 element followed by a button. The button is created using an input HTML element.</p>
                    <p className={classes.main}>The button has been created with an onclick (or to consider this another way, when the user clicks on the button do <i>something</i>). When the user clicks on the button
            the HelloWorld function will be called and a message will be shown to the user which will be "Hello World".</p>
                    <p className={classes.main}></p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default JavaScriptIntro;
