import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Image from 'material-ui-image';
import Footer from "components/Footer/Footer.js";

import PageHeaderSection from "views/HomePage/Sections/PageHeaderSection.js";

// nodejs library that concatenates classes
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function HtmlLab2(props) {
    const classes = useStyles();
    const rest = { ...props };
    return (
        <div>
            <PageHeaderSection {...rest} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <h1 className={classes.title}>Lab 2 - use newly discovered formatting HTML elements</h1>
                    <h2 className={classes.title}>Tools required</h2>
                    
                    <p className={classes.main}>CodePen</p>
                    
                    <h2 className={classes.title}>Steps</h2>
                    <ol>
                        <li>Open CodePen and create a new "Pen".</li>
                        <li>Using your new found knowledge, create the following page:
                    <p className={classes.main}>
                                <b>Note, the green text has been included for guidance only. Please don't attempt to
                        recreate the green text in your HTML page.</b>
                            </p>
                            <p className={classes.main}>
                                <Image alt="Html Formatting Lab" src={require("assets/img/lab2-sample-webpage.png")} />
                            </p>
                        </li>
                        <li>Save your new Pen as html-formatting-lab2</li>
                    </ol>
                    <h2 className={classes.title}>Part 2 - lists</h2>
                    <p>Add both a ordered list and un-ordered list of items to the bottom of the page.</p>

                </div>
            </div>
            <Footer/>
        </div>
    );
}
