import React from 'react';

// core components
import { Link } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

function TutorialSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Want to learn something new?</h2>
          <p className={classes.description}>If you are interested in coding, you are in the right place. To find out more, click on the links below.</p>
          <p className={classes.description}><Link href={process.env.PUBLIC_URL + "/html-intro"}>Web page basics</Link></p>
          <p className={classes.description}><Link href={process.env.PUBLIC_URL + "/css-intro"}>Cascading Style Sheet</Link></p>
          <p className={classes.description}><Link href={process.env.PUBLIC_URL + "/javascript-intro"}>JavaScript Intro</Link></p>
          <h3 className={classes.title}>Labs</h3>
          <h4 className={classes.title}>Code Pen</h4>
          <p className={classes.description}>CodePen is a great online code editor focusing on web page development using HTML, CSS and JavaScript. It allows you to "Pen" a page quickly and see the results in a preview as you type and then save it for later use. CodePen also has the ability to create templates to reduce the developer from having to type the same code over and over again. The following labs work through CodePen basics.</p>
          <ul>
            <li><Link href={process.env.PUBLIC_URL + "/codepen-registration"}>Setup an account using the online code editor, CodePen</Link></li>
            <li><Link href={process.env.PUBLIC_URL + "/codepen-tour"}>Code Pen - how to use it?</Link></li>
            <li><Link href="https://youtu.be/syqtwZcPDr4">Create a new Code Pen Template</Link></li>
          </ul>
          <h3 className={classes.title}>HTML</h3>
          <ul>
            <li><Link href={process.env.PUBLIC_URL + "/HtmlLab1"}>Lab 1 - Create a basic web page</Link></li>
            <li><Link href={process.env.PUBLIC_URL + "/HtmlLab2"}>Lab 2 - Using formatting elements</Link></li>
          </ul>
          <h3 className={classes.title}>JavaScript</h3>
          <ul>
            <li><Link href={process.env.PUBLIC_URL + "/JsLab1"}>Lab 3 - First interactive web page</Link></li>
            <li><Link href={process.env.PUBLIC_URL + "/jsLab2"}>Lab 4 - Talk to the user</Link></li>
          </ul>
          <h3 className={classes.title}>Cascading Style Sheets</h3>
          <ul>
            <li><Link href={process.env.PUBLIC_URL + "/css-lab-1"}>Lab 5 - Customize your style</Link></li>
            <li><Link href={process.env.PUBLIC_URL + "/css-lab-2"}>Lab 6 - Class style</Link></li>
          </ul>
          <h3 className={classes.title}>Code Club Presentations</h3>
          <li><Link href={process.env.PUBLIC_URL + "/Code club.pptx"}>Full presentation 20th Feb 2020</Link></li>
          <li><Link href={process.env.PUBLIC_URL + "/Code club - presentation.pdf"}>Full presentation 20th Feb 2020 - PDF</Link></li>
        </GridItem>
      </GridContainer>
    </div>

  );
}

export default TutorialSection;